import React from "react"
import { Form } from "react-bootstrap"

function FullPageSpinner(){
  return(
    <div className="d-flex flex-column min-vh-100 min-vw-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <div className="spinner-border text-success animate-spin inline-block rounded-full"
          style={{width: "5rem", height: "5rem"}}
         role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    </div>
  )
}

function FullPageErrorFallback({error}) {
    return (
      <div
        role="alert"
        css={{
          color: 'danger',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>Oh no... Tenemos un problema. Por favor, actualiza la app.</p>
        <pre>{error.message}</pre>
      </div>
    )
}

function convertToSlug(Text) {
  return Text.toLowerCase()
             .replace(/[^\w ]+/g, '')
             .replace(/ +/g, '-');
}

const errorMessageVariants = {
    stacked: {display: 'block'},
    inline: {display: 'inline-block'},
  }

function ErrorMessage({error, variant = 'stacked', ...props}) {
    return (
      <div
        role="alert"
        css={[{color: 'danger'}, errorMessageVariants[variant]]}
        {...props}
      >
        <span>Ocurrió un error: </span>
        <pre
          css={[
            {whiteSpace: 'break-spaces', margin: '0', marginBottom: -5},
            errorMessageVariants[variant],
          ]}
        >
          {error.message}
        </pre>
      </div>
    )
}

function Loading({text}){
  return(
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">{(text) ? text : 'Cargando'} ...</span>
      </div>
    </div>
  )
}

function useSafeDispatch(dispatch) {
  const mountedRef = React.useRef(false)
  React.useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  return React.useCallback(
    (...args) => (mountedRef.current ? dispatch(...args) : void 0),
    [dispatch],
  )
}

function asyncReducer(state, action) {
  switch (action.type) {
    case 'pending': {
      return {status: 'pending', data: null, error: null}
    }
    case 'resolved': {
      return {status: 'resolved', data: action.data, error: null}
    }
    case 'rejected': {
      return {status: 'rejected', data: null, error: action.error}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function useAsync(initialState) {
  const [state, unsafeDispatch] = React.useReducer(asyncReducer, {
    status: 'idle',
    data: null,
    error: null,
    ...initialState,
  })

  const dispatch = useSafeDispatch(unsafeDispatch)

  const {data, error, status} = state

  const run = React.useCallback(
    promise => {
      dispatch({type: 'pending'})
      promise.then(
        data => {
          dispatch({type: 'resolved', data})
        },
        error => {
          dispatch({type: 'rejected', error})
        },
      )
    },
    [dispatch],
  )

  return {
    error,
    status,
    data,
    run,
  }
}

const FormInput = ({label, type, placeholder, help, ...props}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label} </Form.Label>
      <Form.Control type={type} placeholder={placeholder} />
      <Form.Text 
        className="text-muted" 
        {...props}>
      {help}
      </Form.Text>
    </Form.Group>
  )
}

export {
    ErrorMessage,
    FullPageSpinner,
    FullPageErrorFallback,
    FormInput,
    Loading,
    useAsync,
    convertToSlug
}