import * as React from 'react'
import {createRoot} from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './app.css';
import { App } from './app'
import {AppProviders} from './context';

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <AppProviders>
        <App/>
    </AppProviders>
)